@import "components/global.scss";

.wrapper {
  padding: $scale;
}

.nav {
  display: flex;

  max-width: 100%;
  justify-content: center;
  margin-bottom: $scale2;

  a {
    color: $c-tyro-blue;
    font-size: $scale-1;
    margin-right: $scale;
    text-decoration: none;
    margin: $scale;
    width: 90px;
  }
}

button {
  margin-left: auto;
}

.transparent {
  background-image: none;
  background-color: transparent;
}
