@import "components/global.scss";

.base {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 5px;
}

.loading {
  position: relative;
  &:after {
    // display: none;
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    right: $scale;
    top: $scale1;
    z-index: 3;
    background-size: contain;
    background-image: url("./icons/ico-loader.svg");
    transform-origin: center center;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
  }
}

.btn {
  display: inline-block;
  color: white;
  font-size: $scale;
  text-align: center;
  text-decoration: none;
  padding: $scale $scale5;
  border-radius: $radius1;
  background-color: $c-green;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: darken($c-green, 6%);
    transition: all 0.4s ease-in-out;
  }
}

.red {
  background-color: $c-red;

  &:hover {
    background-color: darken($c-red, 5%) !important;
    border-color: darken($c-red, 5%) !important;
  }
}

.blue {
  background-color: $c-blue-400;

  &:hover {
    background-color: darken($c-blue-200, 5%) !important;
    border-color: darken($c-blue-200, 5%) !important;
  }
}

.green {
  background-color: $c-green;

  &:hover {
    background-color: darken($c-green, 5%) !important;
    border-color: darken($c-green, 5%) !important;
  }
}

.brown {
  background-color: $c-brown-300;

  &:hover {
    background-color: darken($c-brown-300, 10%) !important;
    border-color: darken($c-brown-300, 5%) !important;
  }

  svg {
    color: floralwhite;
  }
}

.small {
  font-size: 0.9em;
  padding: 0.8em $scale2;
}

.big {
  @media (min-width: $mobile) {
    font-size: $scale1;
  }
}

.outline {
  color: $c-text;
  background: transparent;
  border: 1px solid $c-brown-300;

  &:hover {
    color: white;
    border-color: $c-red;
    background: $c-red;
  }

  svg {
    color: $c-brown-300;
  }
}

.text {
  cursor: pointer;
}

.iconButton {
  background-color: $c-dark-500;
  display: inline-block;
  padding: 6px 5px 1px 5px;
}

.iconText {
  position: relative;
  //padding-left: $scale4 + $scale;
  svg {
    position: relative;
    left: -6px;
    top: 1px;
  }
}

.iconTextOnly {
  padding-left: $scale3;
}

.fullWidth {
  width: 100%;
}

.rounded {
  border-radius: $scale3;
}

.alignRight {
  padding-left: 0;
  padding-right: $scale2;
}

.alignLeft {
  padding-left: $scale2;
  padding-right: 0;
}

.relative {
  //position: relative;
}

.absolute {
  position: absolute;
}

svg {
  margin-top: -2px;
}
