@import "components/global.scss";

.spaceViewContainer {
  display: flex;
  flex-direction: column;

  @include respond-to("landscape") {
    height: 100vh;
  }

  // table and mobile layout
  @include respond-to("portrait") {
    min-height: calc(100vh - 50px);
  }

  section {
    text-align: left;

    padding: $scale1;
    padding-top: 0;

    @include respond-to("landscape") {
      margin-bottom: $scale;
    }

    // table and mobile layout
    @include respond-to("portrait") {
      padding: $scale1 $scale;
    }

    // iphone se
    @media (max-width: 376px) {
      margin-bottom: $scale1;
    }

    section {
      // table and mobile layout
      @include respond-to("portrait") {
        padding: 0;
      }
    }
  }

  .workspace {
    border-radius: $radius2;
    // table and mobile layout
  }
}

.sticky {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  transition: top 0.25s ease-in-out;
  box-shadow: 0 10px 30px 10px $c-beige-100;
  background-color: $c-beige-100;
}
.top {
  display: flex;
  min-height: $scale6;
  flex-direction: row;
  border-bottom: 1px dashed $c-border-light;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  .middle {
    flex: 1; // Ensure the middle container takes up available space
    text-align: left; // Ensure text inside the middle container is left-aligned
  }

  img {
    width: 25px;
    height: 25px;
    margin-right: $scale;
    border: 2px solid $c-brown-200;
    border-radius: 50%;
    cursor: pointer;
    display: inline;
  }

  button.icon,
  button.newIcon,
  button.infoIcon {
    background-color: transparent;

    svg {
      color: $c-brown-200;
      margin-top: -1px;
    }

    &:hover {
      background-color: $c-beige-200;
      svg {
        color: $c-brown-300;
      }
    }
  }
}

.header {
  padding-bottom: $scale;
  justify-content: left;
}

h4 {
  color: $c-text;
  width: 100%;
  margin: 0;

  h4.sessionTitle {
    @include respond-to("landscape") {
      b {
        color: $c-blue-300;
      }
    }
  }
  @include respond-to("portrait") {
    border-bottom: none;
    padding: $scale-2 0 0 0;
    font-size: $scale1;
  }
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

h1 {
  margin-bottom: 0;
}
.middle {
  display: flex;
  flex: 1;
  overflow: auto;
}
.bottom {
  flex: none;
  cite {
    font-size: 0.7em;
    margin-left: $scale;
    text-align: center;
    margin-top: $scale-2;
  }

  @include respond-to("portrait") {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $c-beige-100;
    box-shadow: 0 -10px 30px 10px $c-beige-100;
    padding-bottom: $scale-2;
    z-index: 2;
  }
}
.left-col {
  position: relative;
  margin-right: $scale;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  min-width: 500px;
  padding-bottom: $scale-2 !important;
  overflow: visible;

  // table and mobile layout
  @include respond-to("portrait") {
    min-width: 90%;
    margin: 0 $scale-4 $scale6 $scale-4;
    background-color: transparent;
    border: 0px solid $c-border !important;
    box-shadow: none;
  }

  .chatArea {
    flex: 1;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond-to("portrait") {
      padding-bottom: 50px;
      overflow: visible;
    }

    ol {
      padding-left: 20px; // This will indent the list items
    }
  }
  .bottom {
    flex: none;
  }
}

img.chatroom {
  border-radius: 50% !important;
}
img.workspace {
  border-radius: 50% 50% 0 0 !important;
}

.right-col {
  position: relative;
  flex-direction: column;
  display: flex;
  flex: 0 0 300px;

  .top {
    display: flex;
    margin-bottom: $scale;
  }
  .middle {
    flex: 1 1 0%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    flex-direction: column; // Add this line
    justify-content: space-between; // Add this line
  }
  .bottom {
    flex: none;
  }
}
// Chat

#msg {
  text-align: left;
  padding: 0 $scale2;

  clear: both;

  // table and mobile layout
  @include respond-to("portrait") {
    padding: 0;
  }
}

#msg .loaderSmall {
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  pointer-events: none;
}

#msg .loaderSmall svg {
  fill: $c-brown-200;
}

#msg .user {
  padding: $scale-5;
}

.questionWrapper {
  //display: flex;
  border-radius: $radius-lg;
  border: 1px solid $c-brown-200;
  background-color: $c-beige-50;
  color: $c-text-med-dark;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  outline: none !important;
  border: 2px solid $c-brown-200;
  color: #707070;

  display: flex;
  align-items: center;

  &:hover {
    background-color: #fff;
    transition: all 0.5s ease;
  }

  @include respond-to("portrait") {
    width: 90%;
    margin: auto;
  }
}

#question {
  resize: none;
  border-radius: $radius-lg;
  flex-grow: 1;
  &::-webkit-scrollbar {
    width: 0px;
  }

  overflow: auto;
  width: calc(100% - 110px);
  padding: 18px 0px 5px 18px;
  transition: all 0.5s ease;
  border: none;
  background-color: transparent;
  line-height: 1em;

  // table and mobile layout
  @include respond-to("portrait") {
    padding: 15px 10px 5px 10px;
    line-height: 1em;
  }
}

#questionForm button {
  background-color: $c-brown-200;
  color: $c-beige-100;
  border-radius: 50%;
  border: none;
  outline: none !important;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 40px;
  height: 40px;
  margin: 0 $scale-4;
  padding: 0;

  svg {
    width: 20px;
    height: 20px;
    margin: 5px 0 0 0;
    padding: 0;
  }
}
#questionForm button:hover {
  background-color: $c-brown-400;
  color: #fff;
}

#questionForm button:focus:active {
  @include respond-to-device("mobile") {
    background-color: $c-brown-300;
  }
}

#questionForm button:focus {
  background-color: $c-brown-400;

  @include respond-to-device("mobile") {
    background-color: transparent;
    transition: all 0.5s ease;
  }
}

.starters {
  transform-origin: bottom;

  .expandBtn {
    // margin: auto;
    // display: block;
    margin-left: 0px;
    background-color: $c-beige-300;
    color: $c-brown-400;
    &:hover {
      background-color: $c-blue-300;
      color: $c-text-light;
    }
  }
}

.resumeOverlay {
  img {
    width: 85px;
    height: 85px;
    margin: $scale auto;
  }
}
.infoOverlay {
  .files {
    background-color: $c-beige-300;
    border-radius: $radius;
    padding: $scale2;
    margin-top: $scale2;

    h4 {
      border-bottom: dashed 1px $c-brown-200;
      font-weight: bold;
      margin-bottom: $scale2;
    }

    .file {
      background-color: $c-beige-50;
      border-radius: $radius;
      margin-bottom: $scale-2;
      padding: $scale-2;
      display: flex;
      align-items: center;

      .fileIcon {
        margin-right: $scale-3;
      }
    }
  }
}

.conversations button {
  margin: $scale auto;
  width: 100%;
}

.super-input-container {
  width: 100%;
  position: absolute;
  bottom: 0;
}
