@import "components/global.scss";

.discover {
  h1 {
    font-size: $scale7;
    margin-bottom: 0;
  }
}
.discover-header {
  background: linear-gradient(to top, $c-dark-300, $c-dark-200);
  border-radius: 0 0 $radius-xlg $radius-xlg;
  margin: -10px 0 $scale3 0;
  padding: $scale3 !important;
  display: flex;
  justify-content: space-between;
  color: $c-text-light;
  min-height: 300px;

  // table and mobile layout
  @include respond-to("portrait") {
    margin: 0;
    padding: $scale3 0 !important;
    border-radius: 0 0 $radius-lg $radius-lg;
  }

  .questionWrapper {
    @include respond-to("portrait") {
      width: 100%;
    }
  }

  #question,
  #question &:focus {
    border: none;
    padding: 25px 0px 15px 20px;

    // table and mobile layout
    @include respond-to("portrait") {
      padding: 22px 0px 7px 18px;
    }
  }

  #questionForm button {
    border-radius: $radius-lg;
    width: 50px;
    height: 50px;
    margin-right: $scale-1;

    // table and mobile layout
    @include respond-to("portrait") {
      margin-right: $scale-4;
    }
  }

  h3 {
    font-size: $scale2;
    font-weight: normal;
    margin-bottom: $scale2 !important;

    @include respond-to("portrait") {
      font-size: $scale-1 + $scale-3;
    }
  }
}

.section1 {
  flex-grow: 1;
  margin-right: $scale;
  max-width: 700px;
  padding: 0 $scale3;

  // Table and mobile layout
  @include respond-to("portrait") {
    margin-right: 0;
  }

  h3 {
    margin: 0 0 $scale3 $scale-5;
    color: $c-beige-400;
  }
}

.section2 {
  // Table and mobile layout
  @include respond-to("portrait") {
    display: none;
  }

  border-radius: $radius1;
  width: 22vw;
  min-width: 350px;
  padding: $scale $scale2;
  color: $c-text-light;

  text-align: left;
  .session {
    margin-bottom: $scale-3;
  }

  h2 {
    font-size: $scale1;
    font-weight: normal;
    color: $c-text-light;
    margin-top: 0;
  }
  a {
    color: $c-beige-400;
    text-decoration: none;
    &:hover {
      color: $c-blue-50;
    }
  }

  .progressBar {
    background: $c-beige-400;
    width: 80%;
    padding: 1px;
    border-radius: $radius1;
    margin: $scale 0;
  }
}

.discover-donate {
  border-radius: $radius3 $radius3 $radius-xlg $radius-xlg;

  // Table and mobile layout
  @include respond-to("portrait") {
    border-radius: $radius-lg;
    margin: $scale-3;
  }
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: $scale;
  align-items: stretch;

  // switch to 2 column layout
  @include respond-to("portrait") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: $scale;
  align-items: stretch;

  // switch to 2 column layout

  @include respond-to("portrait") {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid-6 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: $scale;
  align-items: stretch;

  @include respond-to("portrait") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (max-width: $breakpoint-mobile) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.support {
  padding: $scale6;

  h1 {
    margin: 0 0 $scale-5 0;
    display: block;
    font-size: $scale3;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  }

  button {
    color: $c-tyro-blue;
    background-color: floralwhite;

    &:hover {
      background-color: $c-tyro-blue;
      color: white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3),
        0 6px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }

  // table and mobile layout
  @include respond-to("portrait") {
    padding: $scale;

    h1 {
      font-size: $scale1;
    }
  }
}
