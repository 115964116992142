@import 'components/global.scss';

.base {

 

}

.insideButton {

  left: $scale1;

}

.fill {

  fill: white;
  
}

.alignLeft {

  left: 0;
  right: auto;

}

.alignRight {

  left: auto;
  right: 0;

}