@import "components/global.scss";

.base {
  display: block;
  width: $scale7;
  height: $scale3;
  outline: none;

  @media (max-width: $mobile) {
    width: $scale8;
  }
}
