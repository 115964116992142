@import "components/global.scss";
.donate {
  margin: $scale5 $scale3;

  @include respond-to-device("mobile") {
    padding: 0;
  }

  h1 {
    font-size: $scale4;
    color: $c-brown-400;
    margin: 0;
    margin-bottom: $scale-4;
    line-height: $scale;
    b {
      color: $c-tyro-blue;
    }
  }

  h2 {
    font-weight: 100;
    margin: $scale-3 0 $scale 0;
    @include respond-to-device("mobile") {
      font-size: 23px;
    }
  }
  section {
    padding: $scale8 $scale10;
  }
  .homeContent {
    resize: vertical;
    margin: auto;
    padding: $scale7 0;
    max-width: 80%;
    p {
      color: $c-brown-500;
    }
    cite {
      color: $c-blue-500;
    }
  }

  .left h3 {
    font-size: $scale1;
    font-weight: 100;
    color: $c-dark-200;
    margin: 0;
    margin-bottom: $scale-4;
  }

  .right h3 {
    font-size: $scale1;
  }

  p {
    color: $c-dark-200;
  }
}
