@import "components/global.scss";

.base {
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid $c-border;
  transform: scale(1);
  z-index: 1;
  overflow: hidden;
  min-height: 300px;
  color: $c-text-darkest;
  background-size: 100% auto;
  background-position: top center;
  background-color: $c-beige-50;
  transition: transform 0.3s ease-out, filter 0.2s ease-out;
  filter: grayscale(100%) sepia(20%);
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.3),
      0 10px 23px 0 rgba(0, 0, 0, 0.1);

    transform: scale(1.02);
    filter: none;

    .image {
      background-size: 103% auto;
    }
    .buttonBar div {
      transition-delay: 0.2s; // Add this line
      opacity: 1;
      transform: scale(1.02);
    }
  }

  cursor: pointer;
  border-radius: $radius2;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.image {
  aspect-ratio: calc(1 / 1);
  background-size: 100%;
  background-position: 50% 50%;
  transition: all 0.6s ease-out 0s;
  background-repeat: no-repeat;
  transform: scale(1);

  transition: background-size 0.3s ease-out;
  /*
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: -2px;
    left: 0;

    background: linear-gradient(
      to top,
      $c-beige-50 0%,
      $c-beige-50 2%,
      transparent 30%
    );

    border-radius: inherit;
  }
  */
}

.progressBar {
  width: 80%;
  background: $c-brown-200;
  border-radius: 10px;
  height: 2px;
  margin: $scale5 auto 0 auto;
}

.content {
  padding: $scale3 $scale4;
}
h3 {
  font-size: $scale1;
  margin-bottom: $scale-5;
  font-weight: bold;
  @include respond-to("portrait") {
    font-size: $scale;
  }
}
.buttonBar {
  margin: 0;
  padding: auto;
  margin-bottom: $scale;
}
.buttonBar div {
  color: $c-text-light;
  border-radius: $radius2;
  padding: $scale-5 $scale-2;
  background-color: $c-green-300;
  font-size: $scale-1;
  opacity: 0;
  transform: scale(1);
  display: inline-block;
  transition: opacity 0.5s ease-out, transform 1s ease-out;
}

.description {
  margin-bottom: $scale;
  font-size: $scale-1;
  opacity: 0.6;
}

.editBtn {
  position: absolute;
  right: 7px;
  top: 7px;
  display: block;
  z-index: 10;
  &:hover {
    color: $c-text-light;
    cursor: pointer;
  }
}

.card a {
  color: $c-text-dark;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.loading {
  min-height: $scale13;
}

.restrictWidth {
  @media (min-width: $mobile) {
    max-width: $scale15;
  }
}

.noPadding {
  padding: 0;
}

.last {
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.card {
  background-color: $c-primary;
  &:hover {
    padding: 0;
  }
}

.card a.button {
  background-color: $c-bg-200;
}

.card:hover a.button {
  background-color: #000;
}

.card a.button:hover {
  background-color: #78b6cd;
}

.card .button {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.card .actions {
  height: 20px;
}
