@import "components/global.scss";

.input {
  position: relative;
  margin: 0 0 $scale1;
  &:last-child {
    margin-bottom: 0;
  }
}

.textbox {
  &:focus {
    background-color: #fafafa;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.textarea {
  min-height: $scale10;
}

.success {
  border-color: lighten($c-green, 3%);
}

.error {
  color: $c-red;
  margin-bottom: 0;
  border: 1px solid $c-red;
  background-color: lighten($c-red, 38%);
  z-index: 3;
}

.warning {
  background: darken($c-orange, 3%);
}

// placeholders
::-webkit-input-placeholder {
  color: lighten($c-text, 24%);
}

::-moz-placeholder {
  color: lighten($c-text, 24%);
}

:-ms-input-placeholder {
  color: lighten($c-text, 24%);
}

:-moz-placeholder {
  color: lighten($c-text, 24%);
}

.error::-webkit-input-placeholder {
  color: $c-red;
}

.error::-moz-placeholder {
  color: $c-red;
}

.error:-ms-input-placeholder {
  color: $c-red;
}

.error:-moz-placeholder {
  color: $c-red;
}
