@import "components/global.scss";

.base {
  position: relative;
  padding: $scale;
  z-index: 1;
  background-color: $c-beige-200;
  border-radius: $radius2;
  margin-bottom: $scale3;

  h4 {
    margin: 0;

    padding: $scale-2;
    color: $c-text-light;
    border: none;
    display: inline-block;
    background-color: $c-brown-200;
    border-radius: $radius2;
  }

  .expandBtn {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.center {
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

.loading {
  min-height: $scale13;
}

.hidden {
  display: none;
}

.restrictWidth {
  @media (min-width: $mobile) {
    max-width: $scale15;
  }
}

.restrictWidthWide {
  @media (min-width: $mobile) {
    max-width: $scale15 * 2;
  }
}

.noPadding {
  padding: 0;
}

.last {
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.transparent {
  background-color: transparent;
}
