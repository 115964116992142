@import "components/global.scss";

.base {
}

.history {
  tr {
    border: none;
    color: $c-text-med;
    margin: 0;
    overflow: ellipsis;
    display: block;
    font-size: 0.9em;
    line-height: 1.5em;
    margin: 0 $scale-4;
  }

  td {
    padding: 0 !important;

    border: none;
  }
  td div {
    border-radius: $scale;
    padding: $scale-4 $scale;
    cursor: pointer;
  }
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  text-align: left;
  padding-bottom: $scale;
}

thead {
  display: none;
  font-weight: 600;
  background-color: transparent;
  margin-bottom: $scale;

  @media (min-width: $mobile) {
    display: table-header-group;
  }
}

.single_action tbody tr {
  &:hover {
    td div {
      background-color: $c-beige-200;
    }

    /*
    td {
      &:last-child {
        &::after {
          content: ">";
          position: absolute;
          right: 35px; 
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    */
  }

  td {
    position: relative; /* Ensure proper positioning of the arrow */
  }
}

td {
  border-top: 1px dotted $c-border;
}

.th {
  text-align: left;
  outline: none;
  text-transform: capitalize;
  padding: $scale $scale1 $scale1;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.th_select {
  width: 0px;
}

.body {
  tr:last-child td {
    border-bottom: 0;
  }
}

.cell {
  float: left;
  width: 100%;
  padding: 0;
  margin-top: 0.8em;
  margin-bottom: -0.6em;
  text-align: left;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    padding-bottom: $scale;
  }

  @media (min-width: $mobile) {
    float: none;
    width: 30%;
    margin-top: 0;
    margin-bottom: 0;
    padding: $scale1;
    padding-left: 0;
    min-height: 0;

    &:last-child {
      text-align: left;
    }
  }

  a {
    text-decoration: none;
  }
  a:hover {
    color: $c-blue-400;
  }
}

.cell_empty {
  @media (max-width: $mobile) {
    display: none;
  }
}

span.badge {
  margin-left: 0;
}

.inputs {
  margin-bottom: $scale1;

  @media (min-width: $mobile) {
    display: flex;
    margin-bottom: $scale-4;
  }
}

.bulk_actions {
  display: flex;
}

.bulk_actions_button {
  margin-left: $scale-2;

  @media (max-width: $mobile) {
    flex: 1;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.row_actions {
  float: left;
  clear: both;
  white-space: nowrap;
  text-align: left;
  width: 100%;

  @media (min-width: $mobile) {
    float: none;
    clear: none;
    width: auto;
    text-align: right;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.row_actions_button {
  margin: $scale-5;
  transition: opacity 0.2s ease-in-out;
  background-color: $c-blue-400;
  display: flex;
  align-items: center;
  padding: 0 $scale;
  width: auto;
  float: right;

  svg {
    float: none;
  }
}

.loading {
  position: relative;
  padding: $scale5 0;
}

.empty {
  padding: $scale 0;
}

.select {
  float: none;
  vertical-align: top;

  @media (max-width: $mobile) {
    width: $scale4;
    padding-top: $scale;
    vertifical-align: middle;
  }
}

.select_all {
  float: left;
  width: 100%;
  padding-bottom: $scale-1;

  @media (min-width: $mobile) {
    display: none;
  }
}

.image {
  border-radius: 0.2em;
  width: $scale3;
  height: $scale3;
}
