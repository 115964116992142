@import "components/global.scss";

.base {
  .subtext {
    font-size: $scale-2;
    color: $c-brown-400;
    display: block;
    margin-top: -15px;
  }

  .starter {
    margin-bottom: $scale;
    padding: $scale-1;
    border-radius: $radius;
    background-color: $c-beige-50;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $c-blue-50;
    }
  }

  margin-top {
    margin-top: $scale-2;
  }

  .edit {
    position: absolute;
    right: 30px;
    z-index: 10;
    background-color: $c-button-red;
    svg {
      color: white !important;
      height: 14px;
    }

    &:hover {
      background-color: $c-button-hover-red;
    }
  }
  .new {
    margin-bottom: $scale3;
  }
}
