@import "components/global.scss";

.message {
  position: relative;
  padding: $scale3;
  overflow: hidden;
  width: 100%;
  border-radius: $radius;
  margin: $scale1 0;
}

.title {
  padding: 0;
  font-size: $scale1;
  font-weight: 600;
  margin: 0 0 $scale-3 0;
}

.blueBg {
  background-color: lighten($c-blue-300, 24%);
}

.blueText {
  color: $c-blue-300;
}

.greenBg {
  background-color: $c-beige-300;
}

.greenText {
  color: $c-brown-500;
}

.orangeBg {
  background-color: $c-beige-300;

  &svg {
    margin-top: -4px;
  }
}

.orangeText {
  color: darken($c-orange, 10%);
}

.redBg {
  background-color: lighten($c-red, 32%);
}

.redText {
  color: $c-red;
}

.redText + p {
  color: $c-text-light;
}

.icon {
  float: left;
  margin-right: $scale2;
}

.content {
  width: auto;
  overflow: hidden;

  @media (max-width: 400px) {
    float: left;
    margin-top: $scale1;
  }
}

.close {
  position: absolute;
  top: $scale1;
  right: $scale1;
  opacity: 0.6;
  width: 1.3em;
  height: 1.3em;
  cursor: pointer;
  z-index: 1;
  background-color: transparent !important;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
}

.btn {
  margin-top: $scale;
}
