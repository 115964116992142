@import 'components/global.scss';

.blurBg{
    backdrop-filter: blur(4px) brightness(90%);
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 100;
    }