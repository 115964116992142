@import "components/global.scss";

.base {
  h1,
  h2,
  h3,
  h4,
  li,
  ul,
  ol,
  p,
  blockquote {
    margin: 20px 0px;
    padding: 0;
    border: none;
  }

  pre {
    text-wrap: wrap;
  }

  padding: $scale-1 0;
  &:not(:first-child, :nth-child(2)) {
    border-top: dashed 1px $c-border-light;
  }

  .chatMessageText {
    // white-space: pre-wrap;
    unicode-bidi: embed;
    font-size: $scale;
    font-weight: 300;
    margin: 0;
    padding: 0;
    line-height: $line-height;
    word-break: break-word;
    align-content: top;
    display: flex;
    font-size: $scale;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $scale;
      font-weight: bold;
      text-decoration: underline;
    }
    h1,
    h2 {
      font-variant: small-caps;
      font-weight: 900;
      text-decoration: underline;
    }

    strong {
      font-weight: 900;
    }

    .scripture {
      font-family: "Iowan Old Style", "Palatino Linotype", "URW Palladio L",
        P052, serif;
      font-weight: 500;
    }
  }

  .user p {
    margin: 0;
  }

  .userIcon,
  .aiIcon {
    border-radius: 50%;
    position: relative;
    margin-right: $scale-2;
    height: 35px;
    min-width: 35px;
    text-align: center;
    background-color: $c-brown-200;
    color: $c-text-light;
    font-size: $scale1;
    padding-top: 5px;
    margin-left: -$scale-3;
    top: -5px;
  }
  .aiIcon {
    top: 15px;
    margin-left: -7px;
    background-color: $c-blue-200;
  }
  .circle {
    display: block;
    float: left;
    width: $scale1;
    height: $scale1;
    border-radius: 50%;
    position: relative;
    margin-right: 0.5rem;
    top: 0.1em;
  }

  .circle:last-of-type {
    animation: blink 1s linear infinite;
  }
}

.assistant {
  background-color: $c-blue-50;
}

.user p {
  padding: 0;
}
