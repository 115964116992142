@import "components/global";

header.homeHeader {
  display: flex;
  justify-content: space-between;
  padding: $scale $scale5 !important;
  align-items: center;

  .active {
    background-color: transparent !important;
  }
  svg {
    height: 75px;
  }

  button {
    background-color: $c-beige-200 !important;
    color: $c-brown-400 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      // not working
      background-color: $c-brown-300 !important;
      color: white !important;
    }
  }
  .signin {
    display: flex;
    align-items: center;

    a {
      font-size: 0.9em;
      padding: 0 $scale;
    }
  }
}

main.home {
  padding: $scale2 0 0 0 !important;
  min-height: 100vh;

  @include respond-to-device("mobile") {
    padding: 0 !important;
    button {
      font-size: $scale;
    }
  }

  h1 {
    font-size: $scale4;
    color: $c-brown-400;
    margin: 0;
    margin-bottom: $scale-4;
    line-height: 1.2em;
    overflow: visible;

    @include respond-to("portrait") {
      font-size: $scale3;
    }

    b {
      color: $c-tyro-blue;
    }
  }

  h2 {
    font-weight: 100;
    margin: $scale-3 0 $scale 0;
    @include respond-to-device("mobile") {
      font-size: 23px;
    }
  }

  .homeContent {
    // font-family: Charter, "Bitstream Charter", "Sitka Text", Cambria, serif;
    font-weight: normal;
    margin: auto;
    margin-bottom: $scale2;

    // table and mobile layout
    @include respond-to("portrait") {
      padding: 0;
      max-width: 100%;
    }

    p {
      color: $c-brown-400;
    }
    cite {
      color: $c-brown-300;
      font-size: 0.9em;
    }
    section {
      // table and mobile layout
      @include respond-to("portrait") {
        padding: $scale1 0 $scale4 0;
        border-radius: 0;
      }
    }

    .sectionHeading {
      max-width: 550px;
      margin-bottom: $scale8;
      h1 {
        margin-bottom: $scale-4 !important;
      }
      p {
        font-size: 1.2em;
      }

      @include respond-to("portrait") {
        padding: $scale;
        margin-bottom: $scale3;
      }
    }

    .intro {
      width: 100%;
      display: flex;
      justify-content: center; // Centers horizontally if needed
      align-items: center; // Centers vertically
      min-height: 500px;
      background-image: url(./images/banner-church.png);
      background-size: 500px;
      background-position: bottom right;
      font-size: 1.5em;
      margin-bottom: 0;

      .question {
        text-align: center;
        position: relative;
        top: -$scale5;

        @include respond-to("portrait") {
          width: 100%;
        }
      }
      #question {
        padding: 18px 10px 2px 20px !important;
        font-size: $scale-1;

        @include respond-to("portrait") {
          font-size: 0.65em;
        }
      }
      #questionForm {
        max-width: 600px;
        margin: auto;
      }

      @include respond-to("portrait") {
        background-size: 350px auto;
      }

      h1 {
        line-height: 1.2;
        @include respond-to("portrait") {
          font-size: 1.6em;
        }
      }

      p {
        font-size: 1em;
        line-height: 1.4em;

        @include respond-to("portrait") {
          font-size: 0.8em;
        }
      }

      button {
        width: 200px;
        height: 50px;
        font-size: 1.2em;
        margin-top: 10px;
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    .compareOptions,
    .prayerOptions {
      display: flex;
      flex: 1 1 1;
      gap: $scale;
      justify-content: space-between; // This spreads the children across the container

      > * {
        // Targets all direct children
        flex: 1 1 0px; // Adjusts the flex property to make items grow equally and start with a base width of 0px
        min-width: 0; // Prevents flex items from overflowing their container
      }

      @include respond-to("portrait") {
        position: relative;
        display: flex;
        overflow-x: auto;
        scrollbar-width: 0;
        scrollbar-color: transparent transparent;
        gap: $scale;
        justify-content: flex-start;
        scroll-snap-type: x mandatory;
        padding: 0 $scale2;

        > * {
          scroll-snap-align: center;
          width: 200px; // Example min-width, adjust based on your design needs
          flex: 0 0 auto; // Children don't grow or shrink, but can be set to auto width
        }
      }
    }

    .compare {
      .compareOptions {
        margin-top: $scale3;
        h3 {
          display: inline;
        }

        div {
          background-color: $c-blue-300;
          border-radius: $radius;
          padding: $scale1;
          background-color: $c-blue-400;
          color: white;
        }
      }
    }
    .compare {
      .compareCard {
        h4 {
          font-size: 1em;
          color: floralwhite;
        }
        p {
          color: floralwhite;
        }
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s;

        &:hover {
          background-color: $c-blue-500 !important;
          cursor: pointer;
        }

        &.active {
          background-color: $c-brown-400 !important;
        }
      }

      .comparisons {
        display: flex;
        gap: $scale;
        margin: $scale 0 $scale 0;
        height: 600px; // Starting max-height

        transition: height 0.5s ease-in-out, margin 0.5s ease-in-out;
        &.closed {
          height: 0; // Collapsed height
          margin: 0 0 0 0;
        }

        @include respond-to("portrait") {
          margin: $scale3 $scale-2 0 $scale-2;
          height: 500px;
        }
        .buttonContainer {
          display: flex;
          justify-content: center;
          margin: $scale2 0;
        }
        button {
          border: 2px solid $c-brown-300;
          margin: 0 $scale;
        }
        button.notActive {
          background-color: transparent;
          border: 2px solid $c-brown-200;
          color: $c-brown-300;
        }

        strong {
          display: block;
          padding-bottom: $scale-5;
        }

        hr {
          border-color: $c-beige-300; // Use border-color to affect the hr line color
          border-style: solid; // Ensure the border style is set for visibility
          border-width: 1px 0 0 0; // Example border width, adjust as needed
          margin-top: $scale2;
        }
        cite {
          color: $c-blue-500;
          font-size: 1.9em;
        }

        .left,
        .right {
          overflow-y: scroll;
          background-color: floralwhite;
          padding: 0 $scale3;
          border-radius: $radius1;
          flex-grow: 1;
          flex-basis: 50%;
        }
      }
    }

    .topics {
      @include respond-to("portrait") {
        padding-bottom: 0;
      }

      .tryTyro {
        text-align: center;

        button {
          padding: $scale $scale6;
          margin-top: $scale3;
          @include respond-to("portrait") {
            margin-top: 0;

            margin-bottom: $scale5;
          }
        }
      }
    }
    .topics .topicGraphics {
      height: 530px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: $scale4;

      > * {
        flex: 1; // Distribute space evenly
        opacity: 0.5; // Slightly faded look for unselected items
        transform-origin: center; // Ensures scaling is centered
        margin: $scale-4;
        transform: scale(0.95); // Initial scale
        transition: transform 0.5s ease, margin 0.3s, opacity 0.5s ease; // Slower transform transition
      }

      > *.selected {
        transform: scale(1.2); // Scale up
        opacity: 1; // Fully opaque
        z-index: 2; // Ensure it's above other items if they overlap
        margin: $scale3;
        // Ensure the transition duration for transform is consistent with the unselected state
      }

      @include respond-to("portrait") {
        position: relative;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: 0;
        scrollbar-color: transparent transparent;
        gap: $scale;
        justify-content: flex-start;
        scroll-snap-type: x mandatory;
        padding: 0 $scale2;
        margin-bottom: 0;
        height: 530px;

        > * {
          transform: scale(1.2); // Initial scale
          opacity: 0.7; // Fully opaque
          scroll-snap-align: center;
          width: 200px; // Example min-width, adjust based on your design needs
          flex: 0 0 auto; // Children don't grow or shrink, but can be set to auto width
          margin: 0 $scale3;
        }

        > *.selected,
        > *.inView {
          transform: scale(1.3); // Scale up
          opacity: 1; // Fully opaque
          z-index: 2; // Ensure it's above other items if they overlap
          // Ensure the transition duration for transform is consistent with the unselected state
        }
      }
    }
    .topicQA {
      padding: $scale3;
      background-color: $c-blue-50;
      border-radius: $radius1;
      h3 {
        margin-bottom: $scale-2;
      }
    }

    .chatContainerMobile {
      position: relative;
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: 0;
      scrollbar-color: transparent transparent;
      gap: $scale;
      justify-content: flex-start;
      scroll-snap-type: x mandatory;
      padding: 0 $scale2;

      > * {
        scroll-snap-align: center;
        width: 100%;
        flex: 0 0 auto; // Children don't grow or shrink, but can be set to auto width
        margin: $scale3;
      }
    }

    .chatHighlight {
      display: flex;
      margin-bottom: $scale4;
      margin-top: $scale;
      align-items: center;
      background-color: floralwhite;
      border-radius: $radius3;
      padding: $scale3;

      @include respond-to("portrait") {
        flex-direction: column;
        padding: 0;
        background-color: transparent;
        margin-top: 0;
      }

      .chatImage {
        border-radius: 50%;
        width: 256px;
        height: 256px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3),
          0 6px 20px 0 rgba(0, 0, 0, 0.1);
        @include respond-to("portrait") {
          margin-bottom: $scale2;
        }
      }
      .chatQA {
        margin-left: $scale4;

        @include respond-to("portrait") {
          margin: 0;
          text-align: center;
        }
      }
      h2 {
        font-size: $scale3;
        margin-top: 0;
        margin-bottom: $scale-4;

        font-weight: 400;
      }

      h3 {
        margin-bottom: $scale;
      }

      &.inView {
        //animation: scaler 1s forwards 0.5s;

        h2 {
          background: linear-gradient(
            -90deg,
            $c-brown-400,
            $c-brown-400,
            $c-brown-400,
            rgb(255, 238, 162),
            $c-brown-500
          );

          background-size: 400% auto;

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: gradient 3s;
        }
      }
    }
  }

  .active {
    background-color: $c-brown-300 !important; /* Highlight color */
    color: white;
  }

  .prayerCard {
    background-color: $c-blue-100;
    border-radius: $radius1;
    padding: $scale;
    margin: $scale $scale-4;
    border: 2px solid $c-blue-50;
    color: $c-dark-300;

    &.inView {
      //notusing
    }

    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $c-blue-100;
    }

    .prayerText {
      flex-grow: 1;
    }

    .prayerIcon {
      font-size: 1.5em;
    }

    &.active {
      border: 2px solid $c-brown-100;
      background-color: $c-beige-100 !important;
      color: $c-brown-400;
    }
  }
  .prayerQA {
    margin-top: $scale;
    padding: $scale2;
    border: 2px solid $c-brown-100;
    border-radius: $radius1;
  }

  .faqContainer {
    @include respond-to("portrait") {
      margin-top: 0;
    }
  }

  .faq-question {
    background: none;
    border: none;
    text-align: left;
    width: 100%;
    cursor: pointer;
    position: relative;
    background-color: $c-beige-50;
    border-radius: $radius1;
    padding: $scale;
    margin-bottom: $scale;

    &:after {
      content: "+";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.open {
      &:after {
        content: "\2013";
      }
    }

    @include respond-to("portrait") {
      width: 90%;
      margin: $scale auto;
    }
  }

  .faq-answer {
    padding: 0 20px;
    padding-bottom: $scale;
  }

  .fullScreenHeader {
    display: flex;
    align-items: center;
    margin-bottom: $scale;
    gap: $scale;
  }
  .fullScreenContent {
    background-color: floralwhite;
    border-radius: $radius3;

    padding: $scale3;
    overflow-y: scroll;
    margin: auto;

    @include respond-to("portrait") {
      padding: $scale;
      width: 100%;
      overflow-x: hidden;
    }

    .question {
      background-color: #fff;
      border-radius: $radius-lg;
      padding: $scale-2 $scale;
      font-weight: bold;
      border: 1px solid $c-brown-300;
      margin-bottom: $scale2;
      display: block;

      @include respond-to("portrait") {
        border-radius: $radius1;
        margin-top: 0;
      }
    }
  }

  .fullScreenHeader {
    @include respond-to("portrait") {
      display: flex;
      align-items: center; // Align items vertically to the start
      gap: $scale;
      margin-bottom: $scale2;
      margin-top: $scale1;
      padding-right: $scale2;
      image {
        margin: 0 !important;
      }

      h1 {
        font-size: 1.4em;
      }
    }
  }
  h1 {
    margin-bottom: 0 !important;
  }

  .scrollButtonContainer {
    text-align: center;
    button {
      margin: 0 $scale;
      background-color: transparent;
      color: $c-brown-400;
      width: auto;
    }
  }

  .StripeElement {
    background-color: white;
    padding: $scale1 $scale;
    border-radius: $radius;
    input {
      font-family: monospace;
    }
  }

  .markdown {
    line-height: 1.6; /* Improve readability */
    color: inherit; /* Inherit text color from parent */
    margin: 2rem auto; /* Center and add space around the content */
    padding: 0 1rem; /* Add some padding to the sides */

    @include respond-to("portrait") {
      padding: 0;
      margin: 0 auto;
    }
    /* Uniform header styles */
    h1,
    h2,
    h3 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-weight: bold;
      font-size: 1.2em; /* Same size for h1 to h3 */
    }

    h4,
    h5,
    h6 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-weight: bold;
      font-size: 1.1em; /* Same size for h4 to h6 */
    }

    /* Styling for paragraphs */
    p {
      margin: 1rem 0;
    }

    /* Styling for blockquotes */
    blockquote {
      margin: 1rem 0;
      padding: 0.5rem 1rem;
      border-left: 3px solid;
      background: inherit; /* Inherit background color */
    }

    /* Styling for code blocks */
    pre,
    code {
      background: inherit; /* Inherit background color */
      padding: 0.2rem;
      font-family: monospace; /* Use monospace for code for better readability */
      white-space: pre-wrap; // Allows the text to wrap instead of extending outside the container
      word-wrap: break-word; // Breaks words to prevent horizontal scrolling
      overflow-wrap: break-word; // Ensures long words do not cause the element to overflow its container
      max-width: 100%; // Prevents the elements from extending beyond the width of their container
    }

    /* Uniform list styling */
    ul {
      margin: 1rem 0;
      padding-left: 1rem; /* Reduced indentation for unordered lists */
    }

    ol {
      margin: 1rem 0;
      padding-left: 2rem; /* Adjusted indentation for ordered lists */
    }

    li {
      margin: 1rem 0;
    }

    /* Styling for links */
    a {
      color: inherit; /* Inherit text color */
      text-decoration: underline;
    }

    /* Simplified styling for tables */
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th,
    td {
      border: 1px solid;
      padding: 8px;
    }
  }

  .gold {
    background: linear-gradient(
      -90deg,
      $c-brown-300,
      $c-brown-300,
      rgb(255, 235, 162),
      $c-brown-300,
      $c-brown-300
    );

    background-size: 600% auto;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: infiniteGradient 8s infinite; /* 3s for the animation + 2s for the "pause" */
  }
}

main.docs {
  padding: $scale4;
  h3 {
    margin: $scale2 0;
    color: $c-brown-400;
  }
}

@keyframes infiniteGradient {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes scaler {
  0% {
    transform: scale(1); // Start state: no scale and no vertical movement
  }
  100% {
    transform: scale(1.2); // End state: slightly scaled and moved up
  }
}
