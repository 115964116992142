/* colors */
$c-blue-50: hsl(193, 46%, 90%);
$c-blue-100: #c4e1e9;
$c-blue-200: #94c2d3;
$c-blue-300: #6bb3c7;
$c-blue-400: #5fa1ba;
$c-blue-500: #2781a2;

// brand
$c-tyro-blue: $c-blue-300;
$c-tyro-brown: #514835;

$c-dark-50: hsl(45, 4%, 88%);
$c-dark-100: hsl(45, 4%, 68%);
$c-dark-200: hsl(45, 4%, 48%);
$c-dark-300: hsl(45, 4%, 38%);
$c-dark-400: hsl(45, 4%, 24%);
$c-dark-500: hsl(45, 4%, 14%);

$c-brown-50: hsl(30, 31%, 95%);
$c-brown-100: hsl(39, 25%, 85%);
$c-brown-200: hsl(39, 19%, 70%);
$c-brown-300: hsl(39, 19%, 55%);
$c-brown-400: hsl(39, 19%, 45%);
$c-brown-500: hsl(39, 50%, 26%);
$c-brown-600: hsl(39, 50%, 16%);

$c-beige-50: hsl(48, 33%, 97%);
$c-beige-100: hsl(48, 100%, 98%);
$c-beige-200: hsl(43, 60%, 93%);
$c-beige-300: hsl(43, 50%, 89%);
$c-beige-400: hsl(40, 45%, 80%);
$c-beige-500: hsl(38, 40%, 70%);

$c-red-100: hsl(0, 61%, 90%);
$c-red-200: hsl(0, 61%, 80%);
$c-red-300: hsl(0, 72%, 51%);
$c-red-400: hsl(0, 72%, 45%);
$c-red-500: hsl(0, 72%, 30%);
$c-red: $c-red-300;

$c-green-300: hsl(120, 26%, 65%);
$c-green-400: hsl(120, 26%, 50%);
$c-green-500: hsl(120, 26%, 25%);
$c-green: $c-green-400;

// buttons
$c-button-blue: $c-blue-400;
$c-button-hover-blue: $c-blue-300;
$c-button-active-blue: $c-blue-200;

$c-button-red: $c-red-200;
$c-button-hover-red: $c-red-300;
$c-button-active-red: $c-red-500;

$c-button-green: $c-green-500;
$c-button-hover-green: $c-green-300;
$c-button-active-green: $c-green-400;

$c-button-black: $c-dark-400;
$c-button-hover-black: $c-dark-300;
$c-button-active-black: $c-dark-200;

$c-slategrey-700: #403e3b;
$c-primary: $c-beige-100;

/* colours */
$c-bg: $c-beige-100;
$c-bg-200: $c-beige-200;

$c-border-light: $c-brown-100;
$c-border: $c-brown-200;
$c-border-dark: $c-brown-600;
$c-dark: $c-slategrey-700;

$c-darkblue: #3a4488;
$c-orange: #f0aa61;
$c-tint: #fbfbff;

$c-darkpurple: #556cb6;
$c-yellow: #f0aa61;

/* text */
$c-text: $c-brown-400;
$c-text-light: $c-primary;
$c-text-gold: $c-beige-500;

$c-text-med: $c-brown-200;
$c-text-med-dark: $c-brown-300;
$c-text-dark: $c-brown-400;
$c-text-darkest: $c-brown-500;

$c-text-on-dark: $c-bg;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #d73a17;
