@import "components/global.scss";

.feedback {
  background-color: $c-beige-300;

  textarea {
    min-height: 125px;
    width: 95%;
    margin-top: $scale-3;
  }
}
div.button {
  position: fixed;
  right: $scale;
  bottom: $scale;
  width: $scale5;
  height: $scale5;
  max-width: $scale6;
  z-index: 8;
  cursor: pointer;
  text-align: center;
  border-radius: 100%;
  background-color: $c-red;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
}

div.button:hover {
  width: $scale5 + $scale-5;
  height: $scale5 + $scale-5;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
}

.icon {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feedback {
  position: fixed;
  right: $scale;
  bottom: $scale;
  left: $scale;
  z-index: 101;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.form {
  margin-top: $scale1;
}

.rating {
  text-align: center;
  margin-bottom: $scale2;
}

.ratingButton {
  position: relative;
  display: inline-block;
  margin-right: $scale1;
  width: $scale4;
  height: $scale4;
  cursor: pointer;
  text-align: center;
  transition: 0.15s ease-in-out all;

  &:hover {
    transform: scale(1.2);
  }

  &:last-child {
    margin-right: 0;
  }
}

.ratingIcon {
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: $scale-2;
  right: $scale-2;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
    background-color: $c-red;
  }

  svg {
    margin-top: -1px;
  }
}
