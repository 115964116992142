@import "components/global.scss";

.base {
  position: relative;
  padding: $scale-3;
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid $c-brown-200;
  outline: none;
  margin-left: 2px;
  color: $c-brown-400;

  label {
    margin: 0;
    display: inline-block;
    width: auto;
  }

  .chevron {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: transparent;
    color: $c-brown-400 !important;
  }
}

.hidden {
  display: none;
}

.transparent {
  background-color: transparent;
}
