@import "components/global.scss";

.base {
  position: relative;
  padding: $scale1;
  z-index: 1;
  background: floralwhite;
  color: $c-text-light;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid $c-border;

  &:hover {
    background: $c-button-blue;
    border-color: $c-button-blue;
  }
  &:active {
    background: $c-blue-300;
  }

  cursor: pointer;
  border-radius: $radius2;

  > h2 {
    font-weight: 500;
    margin: 0 0;
    color: $c-text-light;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  h3 {
    color: $c-text;
    font-size: $scale1;
    border-bottom: 1px dashed $c-brown-100;
    padding-bottom: $scale;

    @include respond-to("portrait") {
      font-size: $scale;
    }
  }

  h3::first-line {
    text-indent: 0;
  }
}
.card .description {
  margin-bottom: $scale4;
}

.card .editBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  display: block;

  &:hover {
    color: $c-text-light;
    cursor: pointer;
  }
}

.card a {
  color: $c-text-dark;
}

.card .header {
  position: relative;
  overflow: hidden;
  text-align: left;
  color: $c-text;
  background-color: white;
  border-bottom: 1px dotted $c-border;
}

.card header {
  margin-top: $scale-5;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.loading {
  min-height: $scale13;
}

.restrictWidth {
  @media (min-width: $mobile) {
    max-width: $scale15;
  }
}

.noPadding {
  padding: 0;
}

.last {
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.card {
  background-color: $c-primary;
  &:hover {
    padding: 0;
  }
}

.card a.button {
  background-color: $c-bg-200;
}

.card:hover a.button {
  background-color: #000;
}

.card a.button:hover {
  background-color: #78b6cd;
}

.card .button {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.card .actions {
  height: 20px;
}
