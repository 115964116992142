@import "components/global.scss";

.auth {
  text-align: center;
  @include respond-to("portrait") {
    margin: $scale;
  }

  header + section > button {
    display: block;
    margin: 0 auto $scale1;
  }
}

.splitView {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;

  @include respond-to("portrait") {
    flex-direction: column;
  }

  .logo {
    @include respond-to("portrait") {
      width: 70px;
    }

    @include respond-to-device("mobile") {
      margin-bottom: $scale;
    }
  }

  section {
    width: 450px;

    @include respond-to("portrait") {
      width: 100%;
      padding: $scale5 $scale5;
    }
    @include respond-to-device("mobile") {
      padding: $scale3 $scale;
    }
  }

  li {
    list-style-type: disc;
    margin-left: $scale2;
    line-height: 2em;
  }

  .right {
    background-color: $c-beige-200;
    height: 100%;
    width: 30%;
    min-width: 200px;
    display: flex;
    align-items: center;
    padding: 0 $scale5;
    // background-image: url(../../../views/website/images/banner-church.png);
    // background-size: 500px;
    // background-position: bottom right;
    order: -1; // Move .right to the top in portrait mode

    @include respond-to("portrait") {
      padding: $scale8;
      width: 100%;
    }
    @include respond-to-device("mobile") {
      padding: $scale2 $scale3 $scale5 $scale3;
    }
  }

  .split__left,
  .split__right {
    width: 48%;
    @include respond-to("portrait") {
      width: 100%;
    }
  }

  .magicContainer {
    padding-top: 0;
    margin-top: 0;
  }
}
