@import "components/global.scss";

.base {
  background: linear-gradient(to top, $c-dark-300, $c-dark-200);
  border-radius: $radius1;
  padding: $scale1;

  /// table and mobile layout
  @include respond-to("portrait") {
    padding: $scale-2 $scale-1;
  }

  h3 {
    color: $c-text-gold;
  }

  p {
    color: $c-text-light;
  }

  h3 {
    font-size: $scale;
    margin-bottom: $scale-5;
  }
  p {
    font-size: $scale-1;
    margin: 0;
  }

  .images {
    display: flex;
    position: relative;
    margin: $scale-1 auto;

    img {
      width: 35%;
      height: 35%;
      border-radius: $radius1;
      box-shadow: 0 0 5px 2px $c-dark-400;

      &:nth-child(1) {
        z-index: 1;
        margin-right: -$scale-2; // Adjust this value as needed
        opacity: 0.8;
      }

      &:nth-child(2) {
        z-index: 2;
        margin-top: -$scale-6; // Adjust this value as needed
        transform: scale(1.2);
      }

      &:nth-child(3) {
        margin-left: -$scale-6; // Adjust this value as needed
        z-index: 1;
        opacity: 0.8;
      }
    }
  }

  &:hover,
  &:active {
    transform: scale(1.02);
  }

  &:active {
    transition: transform 0.2s;
    transform: scale(1.04);
    z-index: 1000;
  }
  transition: transform 0.2s;
}
