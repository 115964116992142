@import "components/global.scss";

.table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: -$scale2;
  border-collapse: separate;
}

.thead {
  display: none;
  font-weight: 600;

  @media (min-width: $mobile) {
    display: table-header-group;
  }
}

.th {
  text-align: left;
  outline: none;
  text-transform: capitalize;
  padding: $scale $scale1 $scale1;
  border-bottom: 1px dotted darken($c-border, 3%);

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.th_select {
  width: 0px;
}

.body {
  tr:last-child td {
    border-bottom: 0;
  }
}

.cell {
  float: left;
  width: 100%;
  padding: 0;
  margin-top: 0.8em;
  margin-bottom: -0.6em;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    padding-bottom: $scale;
  }

  @media (min-width: $mobile) {
    float: none;
    width: 30%;
    margin-top: 0;
    margin-bottom: 0;
    padding: $scale1;
    padding-left: 0;
    min-height: 0;

    &:last-child {
      text-align: center;
    }
  }
}

.cell_empty {
  @media (max-width: $mobile) {
    display: none;
  }
}

span.badge {
  margin-left: 0;
}

.inputs {
  margin-bottom: $scale1;

  @media (min-width: $mobile) {
    display: flex;
    margin-bottom: $scale-4;
  }
}

.bulk_actions {
  display: flex;
}

.bulk_actions_button {
  margin-left: $scale-2;

  @media (max-width: $mobile) {
    flex: 1;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.row_actions {
  float: left;
  clear: both;
  white-space: nowrap;
  text-align: left;
  width: 100%;
  padding-bottom: $scale;
  margin-top: $scale1;
  border-bottom: 1px solid $c-border;

  @media (min-width: $mobile) {
    float: none;
    clear: none;
    width: auto;
    text-align: right;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.row_actions_button {
  display: inline-block;
  opacity: 0.9;
  white-space: nowrap;
  top: -0.3em;
  margin-right: $scale-3;
  background-color: transparent;
  transition: opacity 0.2s ease-in-out;

  &:last-child {
    margin-right: 0;
  }
}

.loading {
  position: relative;
  padding: $scale5 0;
}

.empty {
  padding: $scale 0;
}

.select {
  float: none;
  vertical-align: top;

  @media (max-width: $mobile) {
    width: $scale4;
    padding-top: $scale;
    vertifical-align: middle;
    border-bottom: 1px solid $c-border;
  }
}

.select_all {
  float: left;
  width: 100%;
  padding-bottom: $scale-1;
  border-bottom: 1px dotted $c-border;

  @media (min-width: $mobile) {
    display: none;
  }
}

.image {
  border-radius: 0.2em;
  width: $scale3;
  height: $scale3;
}
