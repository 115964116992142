@import "components/global.scss";
@import "components/global/animation.scss";

@keyframes blink {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}

.base {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25px;
  height: 25px;
  transform: translate(-50%, -50%);

  svg {
    > :nth-child(1) {
      fill: $c-tyro-blue;
      animation: blink 1.5s ease infinite;
    }

    > :nth-child(2) {
      fill: $c-tyro-brown;
    }
  }
}
