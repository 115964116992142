@import "components/global";

html {
  background-color: $c-beige-50;

  /* width */
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $c-brown-100;
    border-radius: 50px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  ::-moz-scrollbar-thumb {
    background: $c-brown-100;
    border-radius: 50px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  ::-ms-scrollbar-thumb {
    background: $c-brown-100;
    border-radius: 50px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  /* Fade out the handle when not being hovered or scrolled */
  ::-webkit-scrollbar-thumb:not(:hover),
  body:not(:hover) ::-webkit-scrollbar-thumb {
    opacity: 0;
  }

  ::-moz-scrollbar-thumb:not(:hover),
  body:not(:hover) ::-moz-scrollbar-thumb {
    opacity: 0;
  }

  ::-ms-scrollbar-thumb:not(:hover),
  body:not(:hover) ::-ms-scrollbar-thumb {
    opacity: 0;
  }
}

html,
body {
  display: flex;
  flex-direction: column;
  background-color: $c-beige-100;
}

#root {
  height: 100%;
}

body {
  font-size: $body-font-size;
  padding: 0;
  width: 100%;
  //background-attachment: fixed;
  //overflow-x: hidden;
  //background: linear-gradient(  to bottom right,hsla(47, 100%, 96%, 1),hsla(47, 100%, 100%, 1));
  //background-repeat: no-repeat;
  //background-attachment: fixed;

  &.disableOverflow {
    overflow-y: hidden;
  }

  table {
    width: 100%;
  }

  main.ai {
    @include respond-to("landscape") {
      padding: 0 $scale3;

      padding-left: $scale9;
    }
  }

  .wide-margin {
    @include respond-to("landscape") {
      padding: 0 $scale3;
      padding-left: $scale9;
    }

    @include respond-to("portrait") {
      padding: 50px $scale-2 0 $scale-2;
    }

    padding-bottom: 10px;

    width: 100%;
    height: 100%;
    text-align: left;
  }

  main.home {
    // table and mobile layout
    @include respond-to("portrait") {
      padding: 50px $scale-2 0 $scale-2;
    }
  }

  main.Space,
  main.Discover {
    @include respond-to("portrait") {
      padding: 50px 0 0 0 !important;
    }
  }

  #text main {
    padding-bottom: 100px;
  }

  /* TWO COLUMN FLEXBOX */
  .chat-container {
    //display: flex;
    overflow-y: scroll;
  }

  .chat-text {
    flex: 1;
    // display: flex;
    // flex-direction: column;
    position: relative;
    min-height: 100vh; /* Set minimum height of container to full viewport height */
    height: 100%;
    width: 100%;
  }

  // not using this anymore
  .btn-toggle-history {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 110;
    background-color: transparent;
    color: $c-brown-500;

    @include respond-to("landscape") {
      display: none;
    }

    &:hover {
      color: $c-brown-600;
      background-color: transparent;
    }
  }

  .history-container {
    position: fixed;
    right: 0;
    top: 0;
    width: $scale8 + $scale7 + $scale3;
    padding-left: $scale4;
    margin-right: $scale4;
    @include respond-to("portrait") {
      display: none;
    }

    .inner-div {
      margin-top: $scale4;
    }
  }

  .chat-input-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @include respond-to("landscape") {
      margin: 0 $scale7 + $scale8 + $scale2 0 $scale7 + $scale7;
    }

    @include respond-to("portrait") {
      padding: 0 $scale;
    }
  }

  .chatHeader {
    h1 {
      display: inline;
      float: left;
      margin-bottom: 0;
    }
    button {
      float: right;
    }
  }

  header {
    padding-top: $scale2;
  }

  header p {
    padding-bottom: $scale2;
  }

  header h1 {
    margin-bottom: $scale;
  }

  header.help {
    margin: auto;
    width: 80%;
    margin-bottom: $scale4;
  }

  hr {
    margin-bottom: $scale1;
  }

  button {
    background-color: $c-button-blue;
    color: $c-text-light;
    border-radius: 50px;
    border: none;
    padding: 10px 25px;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
      background-color: $c-button-hover-blue;
      transition: all 0.25s;
    }
  }

  .topics button {
    margin: $scale-4;
  }

  button.black-btn {
    background-color: $c-button-black;
    &:hover {
      background-color: $c-button-hover-black;
      &:active {
        background-color: $c-button-active-black;
      }
    }
  }
  button.red-btn,
  button.removeBtn {
    background-color: $c-button-red;
    color: $c-dark-500;
    &:hover {
      color: $c-text-light;
      background-color: $c-button-hover-red;
      &:active {
        background-color: $c-button-active-red;
      }
    }
  }

  button.green-btn {
    background-color: $c-button-green;
    &:hover {
      background-color: $c-button-hover-green;
      &:active {
        background-color: $c-button-active-green;
      }
    }
  }

  h1 span {
    color: $c-blue-300;
  }

  div.lock {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    font-size: $scale-1;
    background-color: #d59e95;
    border-radius: $radius;
    padding: 0;
    display: block;
  }

  .hidden {
    display: none;
  }

  .promptList h2 {
    margin-bottom: $scale;
  }

  .history h4 {
    font-weight: 100;
    color: $c-brown-200;
    margin-left: $scale;
    svg {
      margin-right: $scale-4;
      margin-bottom: -$scale-5;
    }
  }

  .history .new {
    border-style: dashed;
    border-color: $c-brown-200;
    svg {
      margin-bottom: -$scale-5;
    }
    margin-bottom: $scale-1;
  }
  .history-container button,
  .viewAllHistoryBtn {
    color: $c-brown-100;
    padding: $scale-5;
    padding-left: $scale-2;
    margin: $scale-6;
    margin-bottom: $scale-5;
    font-weight: 100;
    background-color: transparent;
    border-radius: $radius;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: transparent solid 1px;
    width: 100%;

    &:hover {
      background-color: $c-brown-100;
      border: $c-brown-100 solid 1px;
    }

    &:focus {
      background-color: $c-button-active-blue;
      color: $c-text-light;
    }
  }

  .history:hover button {
    color: $c-brown-200;
    &:hover {
      color: $c-brown-400;
    }
  }

  .history:hover button:focus {
    color: $c-text-light;
  }

  .history button.active {
    background-color: $c-button-active-blue;
    color: $c-text-light;
  }

  .history button.viewAll {
    font-weight: 900;
    font-size: $scale2;
    padding-top: 0;
    padding-bottom: $scale-3;
    line-height: $scale-3;
    padding-left: 10px;
    border: none;
    border-radius: 0.3em;
  }
  .history button.viewAll:hover {
    background-color: $c-button-active-blue;
    color: $c-text-light;
  }

  // mobile
  .viewAllHistoryBtn {
    margin-top: $scale-5;
    background-color: transparent;
    width: auto;
    color: $c-brown-200;
    font-size: $body-font-size;

    @include respond-to("landscape") {
      display: none;
    }
    @include respond-to-device("mobile") {
      content: "sd";
    }
    &:hover {
      color: $c-brown-400;
    }
    svg {
      margin-bottom: -$scale-5;
      margin-right: $scale-4;
    }
  }

  .stats {
    position: absolute;
    bottom: $scale;
    left: $scale;
    font-size: $scale-1;
    color: $c-text-light;

    span {
      margin-right: $scale-2;
    }
  }

  .stats .viewEye {
    margin-bottom: -3px;
    margin-right: $scale-4;
  }

  .topicBtn span {
    font-size: $scale1;
    padding-right: $scale-3;
    margin-left: -$scale-4;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }

  // User history
  .table-wrapper {
    width: 95%;
  }
}

// Account Card

.accountCard button {
  background-color: $c-button-black;
  opacity: 0.5;
  border-radius: $radius-lg;
  margin: 0;
  color: $c-dark-100;
  &:hover {
    background-color: $c-button-hover-black;
    opacity: 1;
  }
  &:active {
    background-color: $c-button-active-black;
  }
}

.align-left {
  text-align: left !important;
}

.account table thead {
  font-weight: 900;
  padding: 200px 0;
}

section.help {
  position: relative;
  top: $scale8;
}

// 404 page
.forofor {
  text-align: center;
  position: relative;
}
.forofor div:first-child {
  padding-top: $scale5;
}
.forofor p {
  color: #fff;
}

form.twoFactorForm {
  display: inline-block;
  margin: auto;
  padding-top: 0;
  margin-top: -30px;
}
form.twoFactorForm div {
  position: relative;
  overflow: visible;
}
form.twoFactorForm input {
  display: inline-block;
  position: relative;
}

.btnCloseAccount {
  max-width: 200px;
}
