@import "components/global.scss";

.base {
  position: relative;
  padding: $scale2 $scale3;
  z-index: 1;
  background-color: $c-beige-200;
  border-radius: $radius3;
  text-align: left;
  border: none !important;
  margin-bottom: $scale4;

  // table and mobile layout
  @include respond-to("portrait") {
    padding: $scale2 $scale-3;
    margin: $scale-3;
    margin-bottom: $scale2;
  }

  h2 {
    margin: 0 0 $scale-6 0;
    display: inline-block;
    font-size: $scale3;
    color: $c-text-darkest;
  }

  h3 {
    border: none;
    color: $c-text-dark;
    font-size: $scale;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.account {
    text-align: left;
  }

  header {
    margin-top: 0;
    padding-top: 0;
    // table and mobile layout
    @include respond-to("portrait") {
      margin-left: $scale-3;
    }

    margin-bottom: $scale2;
  }
}

.card .description {
  margin-bottom: $scale4;
}

.viewBtn {
  position: absolute;
  background: none;
  right: 5px;
  top: 5px;
  color: $c-blue-200;
  display: block;

  &:hover {
    color: $c-blue-300;
    background: $c-blue-50 !important;
    cursor: pointer;
    transition: none;
    transform: none;
  }
  // table and mobile layout
  @include respond-to("portrait") {
    color: $c-brown-400;
    background: $c-brown-100 !important;
    padding: $scale-5 $scale-3;
    margin: $scale1 $scale;
    top: 12px;
  }
}

.loading {
  min-height: $scale13;
}

.transparent {
  background-color: transparent;
}
.outline {
  border: 1px solid $c-brown-200 !important;
}

.center {
  text-align: center;
}

.bgimage {
  background: linear-gradient(to top, darken($c-blue-200, 20%), $c-blue-300);
  background-position: center;
}

.dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $c-text-light;
  }
}

.white {
  background-color: #fff !important;
}

.shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}
