@import "components/global";

input,
select,
textarea {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid $c-brown-200;
  outline: none;
  margin: 2px;
  color: $c-brown-400;
  font-family: monospace, monospace;
  font-size: 0.9em;
  line-height: 1.5em;
  -webkit-touch-callout: none; /* Prevents zoom on text touch */
}
input,
textarea {
  width: 100%;
}

input[type="checkbox"] {
  width: 25px;
}

form input:valid,
form textarea:valid {
  background-color: #e7effe; /* Background color when input has valid content */
  color: $c-brown-500;
  font-weight: normal;
}

.form-input-advanced {
  display: none;
  color: red;
}

legend,
label {
  margin-top: $scale;
  display: block;
}

label {
  font-weight: 600;
}

form button {
  margin: $scale 0;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 10000; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
  border-radius: 100em;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
