@import "components/global.scss";

.plans {
  strong {
    color: $c-brown-500;
  }
}
.pricingOptions {
  display: flex;
  gap: $scale;
  margin-top: $scale4;
  flex-direction: row; // Assuming a row-based flex container
  justify-content: space-between; // Space out items, pushing the last one to the bottom
  align-items: stretch; // This will make all flex items the same height

  @include respond-to("portrait") {
    flex-direction: column; // Stack items vertically
    margin: 0 $scale;
  }
  .priceCard {
    position: relative;
    display: flex; // Ensure this is a flex container
    flex-direction: column; // Stack items vertically
    justify-content: space-between; // Space out items, pushing the last one to the bottom
    flex: 1; // Adjusted this line
    background: linear-gradient(to bottom, $c-dark-300, $c-dark-400) !important;
    border-radius: $radius1;
    padding: $scale;
    box-shadow: inset 0 1px 1px 0px rgba(253, 253, 253, 1) !important;

    small {
      color: $c-brown-100;
    }

    button {
      margin: auto 0 0 0; // This ensures the button stays at the bottom
    }

    h3,
    p {
      color: $c-beige-100 !important;
      line-height: 1.45em;
    }

    h3 {
      margin: 0;
    }

    hr {
      border: 1px solid $c-brown-200;
      margin: $scale 0;
    }

    .charm {
      position: absolute;
      top: -8px;
      right: -8px;
      svg {
        fill: $c-red;
        color: transparent !important;
        filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3)); // Adds shadow to SVG
      }
    }
  }

  .explain {
    padding: $scale3 0;
  }

  .priceCard:hover {
    background: linear-gradient(to bottom, $c-dark-300, $c-dark-300) !important;
  }
}
