@import "components/global.scss";

@keyframes blink {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}

.sidebar {
  @include respond-to("landscape") {
    position: fixed;
    width: $scale7;
    height: 100%;
    z-index: 101;
    padding: $scale;
    overflow: hidden;
    background-color: transparent;
    transition: all 0.2s ease-out;
    text-wrap: nowrap;

    &.expanded {
      width: $scale11;
      box-shadow: 0.5em 0em 1em rgba(0, 0, 0, 0.1);
      background: linear-gradient(
        to bottom right,
        hsl(47, 100%, 96%),
        hsl(47, 100%, 97%)
      );

      a span.label,
      div span.label {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
      }

      .logoLink {
        width: 150px;
        transition: width 0.3s ease-in-out;
      }

      .logoSVG .bar {
        transform: translate(-10px, 20px);
        animation: blink 1.5s ease infinite 3s;
        transition: transform 0.3s ease-in-out;
      }
    }

    .logoLink {
      display: block;
      overflow: hidden;
      width: 22px;
      position: absolute;
      top: 30px;
      left: 27px;
      transition: width 0.2s ease-in-out;
    }

    .logoSVG {
      width: 115px;
    }

    .logoSVG .text {
      fill: $c-tyro-brown;
    }

    .logoSVG .bar {
      transform: translate(-210px, 20px);
      fill: $c-tyro-blue;
      transition: transform 0.3s ease-in-out;
    }

    .nav-links {
      padding: 0;
      margin-top: $scale9;

      a,
      div {
        position: relative;
        display: block;
        text-decoration: none;
        color: $c-text;
        cursor: pointer;
        width: 100% !important;
        height: $scale4;
        padding: 0.75em $scale-3;
        font-size: 1em;
        line-height: 1;
        margin-bottom: $scale3;

        .icon {
          position: absolute;
          top: 54%;
          left: $scale-1;
          transform: translateY(-50%);
        }

        .label {
          opacity: 0;
          pointer-events: none;
          margin-left: $scale4;
        }

        &.active,
        &:hover {
          color: $c-text-light;
          border-radius: $radius-lg;
          background-color: $c-button-blue;
          background: lighten($c-button-blue, 5%);
        }

        &.active .icon,
        &:hover .icon {
          color: $c-text-light;
        }
      }
    }
  }
}

.popup {
  .nav-bar {
    display: none;
  }

  @include respond-to("portrait") {
    width: 100%;
    z-index: 115;
    background-color: transparent;

    > a {
      display: none;
    }

    .nav-bar {
      display: block;
      position: fixed;
      top: 0;
      height: 50px;
      background-color: $c-beige-200;
      width: 100%;
      z-index: 101;
      box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.2);
      transition: top 0.25s ease-in-out;

      .logoLink {
        display: block;
        width: 90px;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);

        .logoSVG .text {
          fill: $c-tyro-brown;
        }

        .logoSVG .bar {
          fill: $c-tyro-blue;
        }
      }
    }

    .btn-togglenav {
      position: absolute;
      left: $scale1;
      top: 3px;
      z-index: 110;
      background-color: transparent;
      color: $c-brown-500;
    }

    .btn-toggle-history {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 110;
      background-color: transparent;
      color: $c-brown-500;
    }

    .ai .btn-toggle-history {
      display: block;
    }

    &.open {
      .nav-links {
        display: block !important;
      }
      .nav-bar {
        position: fixed;
      }
    }

    .nav-links {
      position: fixed;
      display: none !important;
      padding: $scale3;
      padding-top: $scale8;
      z-index: 9;
      top: 0;
      left: 0;
      right: 0;
      background-color: $c-beige-200;
      box-shadow: 0 0 5em rgba(0, 0, 0, 0.25);
      transition: all 0.5s ease-in-out;
      width: 100%;
      height: 100%;

      a,
      div {
        position: relative;
        display: block;
        float: none;
        padding: $scale $scale;
        margin: $scale1 auto;

        text-decoration: none;
        cursor: pointer;
        border-radius: $radius-lg;
        color: $c-text-light;
        border-radius: $radius-lg;
        background-color: $c-button-blue;
        background: lighten($c-button-blue, 5%);

        .icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: $c-text-light;
        }

        svg {
          display: inline-block;
          color: $c-text-dark;
          margin-left: 0.75em;
        }

        .label {
          margin-left: $scale4;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          color: $c-bg;
          border-radius: $radius-lg;
          background-color: $c-button-blue;
        }

        &.active .icon {
          color: $c-bg;
        }

        &.active svg {
          color: $c-text-light;
        }
      }
    }
  }
}

.site .popup .nav-bar {
  display: none;
}
