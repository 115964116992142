@import "components/global.scss";

.base {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px); // Add this line

  section {
    padding: $scale3 $scale5 $scale5 $scale5 !important;
    text-align: left !important;

    // table and mobile layout
    @include respond-to("portrait") {
      padding: $scale !important;
    }

    img {
      width: 65px;
      height: 65px;
      // table and mobile layout
      @include respond-to("portrait") {
        width: 45px;
        height: 45px;
        margin: auto !important;
      }
    }
  }
  h1 span {
    color: $c-text-dark !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.close {
  position: absolute;
  top: $scale-2;
  right: $scale-2;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
  z-index: 102;

  &:hover {
    opacity: 1;
    background-color: $c-tyro-blue;
  }

  svg {
    margin-top: -1px;
  }
}

.large {
  .fullscreenContent {
    width: 70%;
    max-height: 80%;

    @include respond-to("portrait") {
      width: 100%;
      max-height: 100%;
    }
  }
}

cite {
  text-align: center;
}

.textCenter {
  section {
    text-align: center !important;
  }
}

.fullscreenContent {
  position: absolute;
  left: 50%;
  top: 50%; // Change this from 55% to 50%
  transform: translate(-50%, -50%);
  text-align: left;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: $radius3 !important;

  // table and mobile layout
  @include respond-to("portrait") {
    section {
      margin-top: $scale1 !important;
      border: 0px solid $c-border !important;
      border-radius: $radius !important;
    }
    width: 90%;
  }

  strong.title {
    color: $c-tyro-blue;
  }

  h4 {
    font-weight: bold;
    border-bottom: 1px dashed $c-border-light;
    margin-bottom: $scale;
    padding-bottom: $scale;
  }

  .header {
    border: 0px solid $c-border !important;
  }
}

.fullscreen-appear {
  opacity: 0;
}

.fullscreen-appear-done {
  z-index: 1000;
  opacity: 1;

  .fullscreen-content {
    opacity: 1;
    top: 50%;
  }
}
