@import 'components/global.scss';

.tabs {
  overflow: hidden;
  text-align: center;
  margin-bottom: $scale;
}

.button {

  position: relative;
  width: 100%;
  padding: $scale $scale5;
  outline: none;
  font-size: 0.85em;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid $c-border;
  width: auto;
  border-bottom: none;


  &:hover {

    background-color: $c-beige-500;
    color: $c-text-dark;

  }
  &:last-child {

    border-bottom: none;

  }
    
}

.buttonInActive {

    color: white;
    background-color: lighten($c-blue-300, 5%);

  }


.buttonActive {
  background-color: $c-beige-500;
}
