@import "components/global.scss";

.subnav {
  position: relative;
  margin-bottom: $scale3;
  //border-top: 1px solid $c-border;
}

.item {
  color: $c-text;
  display: inline-block;
  padding-bottom: $scale;
  margin-right: $scale2;
  text-decoration: none;

  @include respond-to-device("mobile") {
    padding-bottom: 0;
    margin-bottom: $scale-3;
  }

  &.active {
    border-bottom: 2px solid $c-blue-300;
  }
}
