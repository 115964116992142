@import "components/global.scss";

.base {
  position: relative;
  padding: $scale;
  margin-top: $scale;
  z-index: 1;
  text-align: left;
  line-height: $scale3;
  height: 100%;

  button {
    // table and mobile layout
    @include respond-to("portrait") {
      width: 100%;
    }
  }

  header {
    margin-bottom: $scale2;
    h3 {
      padding-top: $scale-4;
    }
  }
  h2 {
    padding-bottom: $scale-2;
    margin-bottom: $scale-2;
    border-bottom: 1px solid $c-brown-100;
  }
  h3 {
    color: $c-text;
    font-size: $scale1;
    padding-bottom: $scale-1;
  }

  p {
    color: $c-brown-400;
    margin-bottom: $scale1;
    line-height: $scale2;
  }

  th {
    font-weight: bold;
  }
  &:last-of-type {
    margin-bottom: $scale3;
  }

  &.account {
    text-align: left;
  }

  header {
    margin-top: 0;
    padding-top: 0;
  }

  svg:first-child {
    color: white;
  }

  cite {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: $scale-1;
    background-color: $c-brown-400;
    color: $c-text-light;
    border-radius: $radius;
    padding: 0 $scale-2;
    display: block;
  }
}

.card .description {
  margin-bottom: $scale4;
}

.hidden {
  display: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.loading {
  min-height: $scale13;
}

.restrictWidth {
  @media (min-width: $mobile) {
    max-width: $scale15;
  }
}

.default {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.last {
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.card a.button {
  background-color: $c-bg-200;
}

.card:hover a.button {
  background-color: #000;
}

.card a.button:hover {
  background-color: #78b6cd;
}

.card .actions {
  height: 20px;
}
