.base {
  background: transparent;

  &:hover {
    background: transparent;
    svg {
      opacity: 1;
    }
  }

  svg {
    color: white;
    margin-top: -2px;
  }
}
.favorite {
  svg {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: white;
    }
  }
}

.favorited {
  svg {
    color: gold;
    fill: gold;
  }
}
