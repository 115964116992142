@import "components/global.scss";

.base {
  position: relative;
  padding: $scale1;
  z-index: 1;
  background: floralwhite;
  color: $c-text-darkest;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: $radius2;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  // table and mobile layout
  @include respond-to("portrait") {
    padding: $scale-5;
  }

  &:hover,
  &:active {
    .thumbnail {
      box-shadow: 0px 4px 10px rgba(211, 203, 91, 0.575);
      transform: scale(1.05);
      transition: transform 0.5s;
      border: 2px solid rgba(211, 203, 91, 0.575);
    }

    .thumbnail img {
      mix-blend-mode: darken;
    }
    .buttonBar {
      background-color: rgba(1, 12, 97, 0.3);
      transition: transform 1s;
      transform: scale(1.05);
    }

    border-color: #fffdf5;
    background: linear-gradient(-25deg, $c-brown-100, floralwhite);
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.3),
      0 10px 23px 0 rgba(0, 0, 0, 0.1);
    background-size: 150% 150%;
    transform: scale(1.02);
    transition: all 0.5s;

    .progressBar {
      background: linear-gradient(
        -15deg,
        $c-brown-300,
        rgb(255, 238, 162),
        $c-brown-300
      );
      background-size: 350% 350%;
      animation: gradient 1s ease;
    }

    h3 {
      background: linear-gradient(
        -90deg,
        $c-brown-500,
        $c-brown-500,
        $c-brown-500,
        rgb(255, 238, 162),
        $c-brown-500
      );
      background-size: 400% auto;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: gradient2 1s;
    }
  }

  &:active {
    transition: transform 0.4s;
    transform: scale(1.04);
    z-index: 1000;
  }

  > h2 {
    font-weight: 500;
    margin: 0 0;
    color: $c-text-light;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  h3 {
    font-size: $scale1;
    margin-bottom: $scale-5;
    font-weight: bold;

    @include respond-to("portrait") {
      font-size: $scale;
    }
  }

  .content {
    padding: $scale;
  }
  button {
    margin: $scale-4;
    padding-top: 4px;
    background-color: transparent;

    &:hover,
    &:active {
      transform: scale(1.1);
      background-color: rgba(1, 12, 97, 0.3);
      svg {
        padding: 2px;
      }
    }

    &:active {
      svg {
        padding: 0;
      }
    }

    &.active {
      background-color: $c-button-black;
    }
  }

  .description {
    margin-top: 0 auto;
    margin-bottom: 0;
    font-size: $scale-1;
    display: block;
    color: $c-text-dark;
  }

  .thumbnail {
    width: 75%;
    overflow: hidden;
    border-radius: 50%;
    margin: $scale2 auto $scale2 auto;
    background-color: floralwhite;
    transition: transform 0.2s;

    // table and mobile layout
    @include respond-to("portrait") {
      margin: $scale-2 auto $scale-3 auto;
    }

    img {
      width: 100%;
      height: auto;
      mix-blend-mode: luminosity;
    }

    &.workspace {
      border-radius: 50% 50% $radius $radius;
      margin: 0 auto $scale2 auto;
      width: 80%;

      // table and mobile layout
      @include respond-to("portrait") {
        margin: $scale-2 auto;
      }
    }
  }

  .buttonBar {
    width: 100%;
    background: $c-brown-200;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .progressBar {
    width: 80%;
    background: $c-brown-200;
    border-radius: 10px;
    height: 2px;
    margin: 10px auto;
  }

  .notActive,
  .notAllowed {
    opacity: 0.5;
    box-shadow: none;
  }

  .editBtn {
    position: absolute;
    right: 5px;
    top: 5px;
    display: block;
    z-index: 10;

    &:hover {
      color: $c-text-light;
      cursor: pointer;
    }
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }

  .loading {
    min-height: $scale13;
  }

  .restrictWidth {
    @media (min-width: $mobile) {
      max-width: $scale15;
    }
  }
}
@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient2 {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
