@import "components/global.scss";

.base {
  .createCard {
    padding: $scale1;
  }
}

.ai_creator {
  position: relative;
}

header button.delete-btn {
  float: right;
}

.Flex2 {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  border-radius: $radius3;
  background-color: $c-beige-200;
  section {
    margin: $scale3;
    text-align: center;
    min-height: 200px;
    width: 100%;
  }
}
