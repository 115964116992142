@import "components/global.scss";

.footer {
  margin-top: $scale5;
  text-align: center;
  align-items: center;
  img {
    opacity: 0.5;
    margin: auto;
  }
}

.copyright,
.address {
  display: block;
  clear: both;
  opacity: 0.7;
  text-align: left;
  font-style: normal;
  font-size: 0.9em;
}

.copyright {
  margin-bottom: $scale1;
  color: $c-text-med;
}

.nav {
  margin-bottom: $scale5;
}

.link {
  color: $c-text-med;
  font-size: 0.9em;
  text-decoration: none;
  margin-right: $scale1;
}
