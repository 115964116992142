@import "components/global.scss";

.base {
  background: #fff;
  border-radius: $radius;
  padding: $scale-2;
  h4 {
    font-size: $scale;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    border: none;
  }
  p {
    font-size: $scale-1;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }

  &:hover {
    background-color: floralwhite;
    transition: all 0.25s;
  }
}
.color1 {
  background-color: hsl(39, 30%, 90%);
}

.color2 {
  background-color: hsl(39, 30%, 92%);
}

.color3 {
  background-color: hsl(39, 30%, 94%);
}

.color4 {
  background-color: hsl(39, 30%, 96%);
}

.color5 {
  background-color: hsl(39, 30%, 98%);
}

.color6 {
  background-color: hsl(39, 30%, 97%);
}

.color7 {
  background-color: hsl(39, 30%, 90%);
}

.color8 {
  background-color: hsl(22, 40%, 93%);
}

.color9 {
  background-color: hsl(39, 20%, 99%);
}

.color10 {
  background-color: hsl(39, 34%, 90%);
}
.dark {
  background-color: $c-beige-200;
  color: $c-brown-500;

  h4 {
    color: $c-brown-400;
  }
  &:hover {
    background-color: $c-beige-300;
  }
}
