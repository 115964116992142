@import "components/global.scss";

.base {
  position: relative;
  z-index: 1;
  color: $c-text-dark;
  cursor: pointer;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;

  h2,
  h3,
  h4 {
    color: $c-text-darkest;
  }

  &:hover,
  &:active {
    .buttonBar {
      background-color: rgba(1, 12, 97, 0.3);
      transition: transform 1s;
      transform: scale(1.05);
    }

    transform: scale(1.02);
    h3,
    h4 {
      background: linear-gradient(
        -90deg,
        $c-brown-500,
        $c-brown-500,
        $c-brown-500,
        rgb(255, 238, 162),
        $c-brown-500
      );

      background-size: 400% auto;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: gradient2 1s;
    }
  }

  &:active {
    transition: transform 0.2s;
    transform: scale(1.04);
    z-index: 1000;
  }

  > h2 {
    font-weight: 500;
    margin: 0 0;
    color: $c-text-light;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: $scale-5;
  }
  h4 {
    font-size: $scale;
    padding-bottom: 0;
  }
  p {
    font-size: $scale-1;
    margin: 0;
  }

  .content {
    padding: $scale $scale2 $scale $scale2;

    /// table and mobile layout
    @include respond-to("portrait") {
      padding: $scale-2 $scale-1;
      button {
        font-size: $scale-1;
      }
    }
  }
  button {
    margin: $scale-4;
    padding-top: 4px;
    background-color: transparent;

    &:hover,
    &:active {
      background-color: rgba(1, 12, 97, 0.3);
      svg {
        padding: 2px;
      }
    }

    &:active {
      svg {
        padding: 0;
      }
    }

    &.active {
      background-color: $c-button-black;
    }
  }

  .description {
    margin-top: 0 auto;
    margin-bottom: 0;
    font-size: $scale-1;
    display: block;
    color: $c-text-dark;
  }

  .thumbnail {
    width: 100%;
    position: relative;
    background: rgb(0, 0, 0);
    border-radius: 50% 50% 0 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.chatroom .thumbnail {
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.content {
  background-color: floralwhite;
  border-radius: 0% 0% $radius $radius;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  flex-grow: 1;

  text-align: center;

  button {
    background-color: $c-brown-300;
    line-height: $scale-1;
    padding: 4px 12px;
  }
}

.chatroom .content {
  background-color: transparent;
  text-align: center;
  box-shadow: none;
  padding: $scale-1 0 0 0;
}

.loading {
  min-height: $scale13;
}

.restrictWidth {
  @media (min-width: $mobile) {
    max-width: $scale15;
  }
}

@keyframes gradient {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient2 {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
