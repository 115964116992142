@import 'components/global.scss';

.base {

  padding: $scale 0;
  text-align: left;

}

.transparent {

  background-color: transparent;

}

.tint {

  background-color: $c-bg-200;

}

.dark {

  color: white;
  background-color: $c-dark;

}

.brand {

  color: white;
  background-color: $c-text-dark;

}

.left {

  text-align: left;

}

.center {

  text-align: center;

}

