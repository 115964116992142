@import 'components/global.scss';

.header  {

  position: relative;
  text-align: center;
  margin-bottom: $scale1;

  &:after {

    content: '';
    position: absolute;
    width: $scale4;
    height: 2px;
    left: 50%;
    bottom: $scale;
    transform: translateX(-50%);

  }
}

.title {

  color: $c-text-dark;
  font-weight: 600;
  margin-bottom: $scale-3;

}

.subtitle {

  
  margin-top: 0;
  margin-bottom: $scale-3;

  @media (min-width: $mobile){

    font-size: $scale2;

  }
}

.brand, .dark {

  color: white;

}